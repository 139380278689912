<template>
  <div class="page_bg">
    <!-- 顶部返回 我要退款-->
    <div class="back_top_name  flex-r-sb-c" style=" padding: 0.32rem;background: #ffffff;font-size: 0.48rem;">
      <van-icon name="arrow-left" @click="backPath" />
      <div class="top_name ">我要退款</div>
      <van-icon name="service-o" @click="openKeFu" />
    </div>
    <!-- 商品信息 -->
    <div class="return_pro_list">
      <div class="cart_company return_pro" style="margin-bottom:0">
        <div class="company_pro_list" style="padding-left: 0.4rem;margin-top:0.2rem">
          <div v-for="(pro_item,pro_index) in cartProInfo.cartCompanies" :key="pro_index" class="flex-r-s-c">
            <proCard05 :info="pro_item" :is-change="false" />
            <van-divider />
          </div>
        </div>
      </div>
    </div>
    <!-- 退款金额 -->
    <div class="return_case_box">
      <div class="case_box_money flex-r-sb-c">
        <div>退款金额<span style="color:#ED0D0D;">*</span></div>
        <div style="margin-right:1rem;color:#ED0D0D;font-size: 0.48rem;">￥{{ cartProInfo.totalPrice }}</div>
      </div>
      <div class="case_box_money_tip">该售后金额由系统自动计算生成，如涉及赠品退回或对此金额有疑问，请联系客服</div>
    </div>
    <!-- 提价申请 -->
    <div class="return_sub flex-r-c-c">
      <van-button round block type="info" loading-text="提交中..." native-type="resubmit" color="#0767ab"
                  style="font-size: 0.43rem;margin-top:0.6rem;margin-bottom:1rem" class="next_btn " @click="sub"
      >提交申请
      </van-button>
    </div>
    <!-- 客服组件 -->
    <kefuBtn ref="mykefuBtn" />
  </div>
</template>

<script>
import './index.scss'
import '../cart/index.scss'
import { mallOrder_buyer_detail, refund_add } from '@/api/order'
import kefuBtn from '@/components/kefu_btn'
import proCard05 from '@/components/pro_card_05'
export default {
  components: { kefuBtn, proCard05 },
  data() {
    return {
      orderId: '',
      cartProInfo: { cartCompanies: [], totalPrice: 0 },
      result: [],
      form: {},
      show: false,
      actions: [],
      fileList01: [],
      fileListcopy: [],
      orderInfo: {},
      refundRes: {}
    }
  },
  created() {
    this.orderId = this.$route.query.orderId
    this.getCartList()
  },
  methods: {
    backPath() { window.history.back() },
    getCartList() {
      // 订单信息
      mallOrder_buyer_detail(this.orderId).then(res => {
        this.orderInfo = res.data
      })
      console.log(JSON.parse(sessionStorage.getItem('returnInfo')))
      this.cartProInfo.cartCompanies = JSON.parse(sessionStorage.getItem('returnInfo')).refundProductList
      this.cartProInfo.totalPrice = JSON.parse(sessionStorage.getItem('returnInfo')).refundAmount
      this.refundRes = JSON.parse(sessionStorage.getItem('returnInfo')).refundRes
    },
    // 提交
    sub() {
      refund_add(this.refundRes).then(res => {
        this.$toast({ message: '已提交', duration: 1 * 1000 })
        this.$router.replace('/order/orderDetail?id=' + this.refundRes.orderId)
      })
    },
    openKeFu() { this.$refs.mykefuBtn.changeShow(true) }

  }
}
</script>

<style lang="scss" >
.cart_company .company_name .van-icon {
  color: #ffffff !important;
  font-size: 0.8em;
}
.van-cell::after {
  border: none;
}
</style>
